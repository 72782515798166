.bodyWithVideo {
  overflow: hidden;
}

.backdrop {
  position: fixed;
  z-index: 10000;
  inset: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(3px);
  background-color: rgb(0 0 0 / var(--tw-bg-opacity));

  --tw-bg-opacity: 0.1;
}

.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: var(--space-xl) var(--space-md);
  width: 100%;
  height: 100%;
  overflow-x: auto;
}

@media (width >= 576px) {
  .wrapper {
    padding: var(--space-xxl);
  }
}

.container {
  position: relative;
  width: 100%;
  height: 100%;
}

.closeButton {
  position: absolute;
  z-index: 10000;
  bottom: 100%;
  left: 100%;
  margin: 0;
  padding: 0.25rem;
  width: 2rem;
  height: 2rem;
  color: rgb(0 0 0 / var(--tw-text-opacity));
  border-color: transparent;
  border-radius: 100%;
  background-color: var(--neutral-0);
  transition: background-color var(--transition);

  --tw-text-opacity: 1;
}

.closeButton:hover {
  background-color: var(--neutral-60);
}

.closeButton {
  display: flex;
  justify-content: center;
  align-items: center;
}

.closeButton .icon {
  width: 1.5rem;
  height: 1.5rem;
  stroke-width: 1.2;
  stroke: #000;
  transition-duration: 150ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

.closeButton:hover .icon {
  stroke: #fff;
}
